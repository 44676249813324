import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Process from "../components/process"
import Whitepaper from "../components/whitepaper"
import PaperList from "../components/paper-list"
import ReadList from "../components/read-list"
import HeaderResearch from "../components/header-research"

const ResearchPage = ({ pageContext: { locale, keywords } }) => (
  <Layout locale={locale}>
    <HeaderResearch />
    <SEO
      title="Research"
      keywords={[
        ...keywords,
        `MASS Blockchain Consensus Engine`,
        `MASS 共识引擎研究历程`,
        `MASS Official Website`,
      ]}
      lang={locale}
    />
    <Process />
    <Whitepaper />
    <PaperList />
  </Layout>
)

ResearchPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    keywords: PropTypes.array.isRequired,
  }).isRequired,
}

export default ResearchPage
