import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"

import "./whitepaper.css"

const Whitepaper = () => (
  <Fragment>
    <div className="whitepaper-container">
      <div className="container">
        <h1 className="whitepaper-title">
          <span>
            <FormattedMessage id="org.massnet.components.research.whitepaper.title" />
          </span>
        </h1>
        <div className="whitepaper-content">
          <h3> MASS: A Blockchain Consensus Engine </h3>
          <a
            href="https://download.massnet.org/research/MASS-A%20Blockchain%20Consensus%20Engine.pdf"
            download=""
            target="_blank"
            className="btn-download-wp"
          >
            <FormattedMessage id="org.massnet.components.research.whitepaper.download" />
          </a>
        </div>
      </div>
    </div>
  </Fragment>
)

export default Whitepaper
