import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"

import "./process.css"

const Process = () => (
  <Fragment>
    <div className="container">
      <h1 className="process-title">
        <span>
          <FormattedMessage id="org.massnet.components.research.process.title" />
        </span>
      </h1>
      <p className="process-content">
        <FormattedMessage id="org.massnet.components.research.process.content.first_param" />
      </p>
      <p className="process-content">
        <FormattedMessage id="org.massnet.components.research.process.content.second_param" />
      </p>
      <p className="process-content">
        <FormattedMessage id="org.massnet.components.research.process.content.third_param" />
      </p>
    </div>
  </Fragment>
)

export default Process
